<template>
    <p-w-a-prompt :debug="false" :copyTitle="'Añadir a la pantalla de inicio'" :copyBody="'Puedes añadir esta página web a tu pantalla de inicio.'" :copyShareButtonLabel="'1) Pulsa el boton \'Compartir\''" :copyAddHomeButtonLabel="'2) Pulsa \'Añadir a la pantalla de inicio\''" :copyClosePrompt="'Cancelar'"/>
</template>

<script>
import PWAPrompt from 'vue2-ios-pwa-prompt'
export default {
    components: {
  		PWAPrompt,
  	},
}
</script>