
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require('vue');
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.

 */

import Vue from "vue"
import IosPrompt from './components/Propmt/IosPrompt'
import locale from 'element-ui/lib/locale/lang/en'
import Element from 'element-ui'

Vue.use(Element, { size: 'large',locale });
const app = new Vue({
    el: '#prompt',
    name:'prompt',
    components: {
        IosPrompt,
  	},
});